var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"regex-condition"},[_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'pattern',
        'validation': 'required',
        'prefix': 'face_control_percent_condition_'
      }
    }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'invert',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }